<template>
	<footer>
		<div class="container-full">
			<h6 class="align-center">
				2023 La galerie d'Anne - Made with ♥ by
				<a
					class="link link-theme"
					href="https://design.lebowyer.com/"
					target="_blank"
					>Le Bowyer</a
				>
			</h6>
		</div>
	</footer>
</template>
