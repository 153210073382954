<template>
	<li>
		<router-link to="/" class="link link-theme" activeClass="link-active"
			>Accueil</router-link
		>
	</li>
	<li>
		<router-link
			to="/galleries/paintings"
			class="link link-theme"
			activeClass="link-active"
			>Peintures</router-link
		>
	</li>
	<li>
		<router-link
			to="/galleries/watercolors"
			class="link link-theme"
			activeClass="link-active"
			>Aquarelles</router-link
		>
	</li>
	<li>
		<router-link
			to="/galleries/drawings"
			class="link link-theme"
			activeClass="link-active"
			>Dessins</router-link
		>
	</li>
	<li>
		<router-link
			to="/events"
			class="link link-theme"
			activeClass="link-active"
			>Événements</router-link
		>
	</li>
	<li>
		<router-link
			to="/artist"
			class="link link-theme"
			activeClass="link-active"
			>L'artiste</router-link
		>
	</li>
</template>
