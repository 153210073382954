<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 12 21"
	>
		<path
			d="M37.8000221,31.5391654 L28.4608346,22.2004698 C28.32727,22.0669053 28.1737815,22 28.000123,22 C27.8264645,22 27.67273,22.0669053 27.5391654,22.2004698 L18.2004698,31.5391654 C18.0666593,31.6729759 18,31.8264645 18,32.000123 C18,32.1737815 18.0669053,32.327516 18.2004698,32.4610806 L19.2023269,33.4629377 C19.3358915,33.5965022 19.489626,33.6631615 19.6632845,33.6631615 C19.836943,33.6631615 19.9906775,33.5965022 20.1242421,33.4629377 L28.000123,25.5870568 L35.8762499,33.4631837 C36.0098144,33.5967482 36.1635489,33.6631615 36.3369615,33.6631615 C36.510866,33.6631615 36.6646005,33.5965022 36.798165,33.4631837 L37.8000221,32.4610806 C37.9335867,32.327516 38,32.1737815 38,32.000123 C38,31.8264645 37.9335867,31.67273 37.8000221,31.5391654 L37.8000221,31.5391654 Z"
			transform="rotate(90 25.5 8.332)"
		/>
	</svg>
</template>
