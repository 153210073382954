<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 12 21"
	>
		<path
			d="M37.8,31.539L28.461,22.2C28.327,22.067 28.174,22 28,22C27.826,22 27.673,22.067 27.539,22.2L18.2,31.539C18.067,31.673 18,31.826 18,32C18,32.174 18.067,32.328 18.2,32.461L19.202,33.463C19.336,33.597 19.49,33.663 19.663,33.663C19.837,33.663 19.991,33.597 20.124,33.463L28,25.587L35.876,33.463C36.01,33.597 36.164,33.663 36.337,33.663C36.511,33.663 36.665,33.597 36.798,33.463L37.8,32.461C37.934,32.328 38,32.174 38,32C38,31.826 37.934,31.673 37.8,31.539Z"
			transform="matrix(3.82857e-16,1,1,-3.82857e-16,-21.8312,-17.168)"
		/>
	</svg>
</template>
