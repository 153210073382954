<template>
	<div class="contact">
		<div class="container-full">
			<h6 class="align-center">
				Pour toute demande, vous pouvez me contacter par
				<a
					class="link link-external"
					href="mailto:anne.bouvyer@gmail.com"
					target="_blank"
					>courrier éléctronique</a
				>.
			</h6>
		</div>
	</div>
</template>
