<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 12 8"
	>
		<path d="M588 15 594 23 600 15 Z" transform="translate(-588 -15)" />
	</svg>
</template>
